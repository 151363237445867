<template>
  <div>
      <card-login/>
  </div>
</template>

<script>
import cardLogin from '../components/Cards/CardLogin.vue'
export default {
  components: {
      cardLogin
  },
  setup() {
      return {

      }
  }
}
</script>

<style>
html, body {
  margin: 0; 
  padding: 0;
  background: white;
  background: linear-gradient(to right, #E32138, #FFC20E);
  height: 100%;
}
</style>