<template>
  <div>
    <list-users v-if="viewListUsers" :users="users" @view-crear-usuario="viewCrear" @editar-user="editar" @eliminar-user="eliminar"/>
    <edit-user v-if="viewEditUser" :user="user" @enviar-usuario="actualizar" @cancelar="cancelarActualizar"/>
    <create-user v-if="viewCreateUser" @crear-usuario='crearUser' @cancelar="cancelar"/>
  </div>
</template>
  <script>
  import editUser from "../Users/EditUser.vue"
  import listUsers from "../Users/ListUsers.vue"
  import createUser from "../Users/CreateUser.vue"
  import axios from "axios";

  export default {
    data() {
      return {
        users: [],
        user: null,
        viewListUsers: true,
        viewEditUser: false,
        viewCreateUser: false,
        indiceAEliminar: null,
      };
    },
    components: {
      listUsers,
      editUser,
      createUser,
    },
    mounted() {
      if (!this.users.length) {
      // Si no están en caché, carga los datos desde la API
      console.log('Se va a consumir el api');
      this.getInfo();
    }

    },
    methods: {
      crearUser(){
        this.getInfo();
        this.viewCreateUser = false;
        this.viewListUsers = true;
      },
      viewCrear(){
        this.viewCreateUser = true;
        this.viewListUsers = false;
      },
      cancelar(){
        this.viewListUsers = true;
        this.viewEditUser = false;
      },
      cancelarActualizar(){
        this.users.splice(this.indiceAEliminar, 0, this.user)
        this.viewListUsers = true;
        this.viewEditUser = false;
      },

      async actualizar (user) {       
        const { id, roles, ...data} = user
        console.log(roles);

        await axios.put(
          `${process.env.VUE_APP_RUTA_API}api/user/${id}`, data, 
          {headers: {'x-token': sessionStorage.getItem('x-token')}
        }
        )
        .then(response => {
          data.id = id;
          data.roles = roles;
          this.users.splice(this.indiceAEliminar, 0, data)
          console.log(response);
      })
      .catch(error => {
        console.error('Error al obtener la lista de usuarios:', error);
      });
      
        this.viewListUsers = true;
        this.viewEditUser = false;
      },

      eliminar(userId) {
        this.indiceAEliminar = -1;
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].id === userId) {
                this.indiceAEliminar = i;
                break;
            }
        }

        if (this.indiceAEliminar !== -1) {
            // Si encontramos el elemento, lo eliminamos del array
            this.users.splice(this.indiceAEliminar, 1);
            console.log("Objeto eliminado");
        } else {
            console.log("Objeto no encontrado");
        }
      },

      editar(userId) {
        this.indiceAEliminar = -1;
        for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].id === userId) {
                this.user = this.users[i];
                this.indiceAEliminar = i;
                break; 
            }
        }
        if (this.indiceAEliminar !== -1) {
            // Si encontramos el elemento, lo eliminamos del array
            this.users.splice(this.indiceAEliminar, 1);
        } else {
            console.log("Objeto no encontrado");
        }
        this.viewListUsers = false;
        this.viewEditUser = true;
      },

      async getInfo() {
            await axios.get(`${process.env.VUE_APP_RUTA_API}api/user`)
                .then(response => {
                // Almacena los datos de la respuesta en la propiedad 'users'.
                this.users = response.data.usuarios;
                console.log(this.users, 'Jersson');
                })
                .catch(error => {
                console.error('Error al obtener la lista de usuarios:', error);
                });
        }
    }
  };
  </script>
  