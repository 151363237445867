<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-3"
  >
    <div
      class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
    >
      <!-- Brand -->
      <div class="flex items-center h-12">
        <img :src="etbIcon" alt="etbIcon" class="">
        <h3 class="text-center px-3 text-white"> Redes <br/> Cuidadanas </h3>
        <img :src="seguridad" alt="secretaria de seguridad Icon" class="h-15">
      </div>
      <!-- Form -->
      <form
        class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
      >
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
            class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
          >
            <i class="fas fa-search"></i>
          </span>
          <input
            type="text"
            placeholder="Search here..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <!-- User -->
      <ul class="flex md:flex-row list-none items-center hidden md:flex m-0">
        <user-dropdown />
      </ul>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script>
import UserDropdown from "@/components/Dropdowns/UserDropdown.vue";
import etbIcon from "@/assets/img/ETB.svg";
import seguridad from "../../assets/img/SecretariaSeguridad.svg"
export default {
  components: {
    UserDropdown,
  },
  setup () {
    return {
      etbIcon,
      seguridad,
    }
  }
};
</script>

<style>
  .contenedor-logos {
    max-height: 5%;
  }
</style>