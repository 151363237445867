<template>
  <div>
    <br>  <br>  
      <div class="w-full contenedor" >
        <!--
            <iframe title="X-TAM - Bogotá - Produccion" iframe :style="iframeStyle"  src="https://app.powerbi.com/view?r=eyJrIjoiYjdmYmNmYjMtM2RmYi00OWQ2LTg0ZDQtNGQ2N2U3NWU1MTU5IiwidCI6ImI4YThhYTE3LTc3ZmMtNDY2Ny05MWI3LWRkOGE0YjZjMWVlNSJ9" frameborder="0" allowFullScreen="true"></iframe>        
        -->        
        <iframe title="X-TAM - Bogotá - Produccion" frameborder="0" class="iframe"  src="https://app.powerbi.com/view?r=eyJrIjoiYjdmYmNmYjMtM2RmYi00OWQ2LTg0ZDQtNGQ2N2U3NWU1MTU5IiwidCI6ImI4YThhYTE3LTc3ZmMtNDY2Ny05MWI3LWRkOGE0YjZjMWVlNSJ9" ></iframe>
      </div>      
    
  </div>
</template>
<script>



export default {
  name: "dashboard-page",
  components: {

  },
};
</script>
<style scoped>
.contenedor {
  width: 100%;
  height: 100vh; /* 100% de la altura del viewport */
  overflow: hidden; /* para ocultar barras de desplazamiento si el contenido del iframe es más grande */
}

.iframe {
  width: 100%;
  height: 100%;
  border: none; /* quitar el borde del iframe si lo deseas */
}
</style>