import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    loginUser: {
      msgError: '',
      rolesUser: [],
      token: ''
    }
  },
  getters: {
  },
  mutations: {

    'CLEAR_DATA_LOGIN': (state) => {
      state.loginUser = {
        msgError: '',
        rolesUser: [],
        token: ''
      }
    },

    'LOGIN_ERROR': (state, payload) => {
      state.loginUser.msgError = payload

    },
    'LOGIN_SUCCESS': (state, payload) => {
      
      state.loginUser.rolesUser = payload.roles,
      state.loginUser.token = payload.token

    },
  },
  actions: {
    reloadedPage({commit}, data) {
      // console.log(data);
      commit('LOGIN_SUCCESS', data)
    },
    deleteInfoBeforeLogin({commit}) {
      commit('CLEAR_DATA_LOGIN')
    }, 
    async validateUserLogin({commit}, credentials) {
      await axios.post(`${process.env.VUE_APP_RUTA_API}api/auth`,
        credentials
        ).then((response) => {
          sessionStorage.setItem('x-token', response.data.token);
          sessionStorage.setItem('user-roles', response.data.usuario.roles);
          sessionStorage.setItem('user-id', response.data.usuario.id);
          commit('LOGIN_SUCCESS',  {token: response.data.token, roles:  response.data.usuario.roles})
      })
      .catch((error) => {
        commit('LOGIN_ERROR', error.response.data.msg)
        console.error("Error al realizar la solicitud a la API:", error);
      });
    }
  },
  modules: {
  }
})
