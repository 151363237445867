import { createWebHistory, createRouter } from "vue-router";

// layouts

import User from "@/layouts/Pantalla.vue";
import Auth from "@/layouts/Auth.vue";

// views for User layout

import Dashboard from "@/views/general/Dashboard.vue";
import Table from "@/views/general/Tables.vue";
import Password from "@/components/Cards/CardChangePassword.vue"

// views for Admin layout
import AdminUsers from "@/views/general/AdminUsers.vue";

// views for Auth layout

import Login from "../components/Cards/CardLogin.vue";
// import Register from "@/views/auth/Register.vue";

const routes = [
    {
      path: "/user",
      redirect: "/user/dashboard",
      component: User,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/user/dashboard",
          component: Dashboard,
        },
        {
          path: "/user/table",
          component: Table,
        },
        {
          path: "/user/changepassword",
          component: Password,
        },
        {
          path: "/user/listUsers",
          component: AdminUsers,
        },
      ],
    },
    {
      path: "/",
      redirect: "/auth/login",
      component: Auth,
      children: [
        {
          path: "/auth/login",
          component: Login,
        }
      ],
    },
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ];


  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      const jwtToken = sessionStorage.getItem('x-token');
      console.log(jwtToken);
      if (!jwtToken) {
        // El usuario no está autenticado, redirige a la página de inicio de sesión
        next('/auth/login');
      } else {
        // El usuario está autenticado, permite el acceso a la ruta
          next();
      }
    } else {
      // La ruta no requiere autenticación, permite el acceso
      next();
    }
  });
export default router;