<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Card Tables
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <div v-if="cambios" class="p-3 text-info-emphasis bg-info-subtle border border-info-subtle rounded-3">
        No hay cambios en el usuario
      </div>
      <!-- Projects table -->
      <form>

        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">First Name</label>
            <input type="text" class="form-control" id="full_name_id" name="first_name" placeholder="First Name" v-model="editedUser.firstName">
        </div>    
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">Last Name</label>
            <input type="text" class="form-control" id="full_name_id" name="last_name" placeholder="Last Name" :value="editedUser.lastName">
        </div> 
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">User Name</label>
            <input type="text" class="form-control" id="full_name_id" name="user_name" placeholder="User Name" :value="editedUser.userName">
        </div> 
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">Email</label>
            <input type="email" class="form-control" id="full_name_id" name="user_name" placeholder="Email" v-model="editedUser.email">
        </div> 
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">Password</label>
            <input type="text" class="form-control" id="full_name_id" name="user_name" placeholder="Password" v-model="password">
        </div>  {{ password }}
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">Roles</label>
            <div v-for="rol in editedUser.roles" :key="rol" class="mb-1">
              <input type="text" class="form-control" id="full_name_id" name="user_name" placeholder="Rol" :value="rol">
            </div>
        </div> 
        <div class="form-group"> <!-- Full Name -->
            <label class="control-label">Company</label>
            <input type="text" class="form-control" id="full_name_id" name="user_name" placeholder="Company" v-model="editedUser.company">
        </div> 

        <div class="form-group"> <!-- Submit Button -->
            <button @click="submit" type="button" class="btn btn-primary">Actualizar</button>
        </div>  
        <div class="form-group"> <!-- Submit Button -->
            <button @click="cancel" type="button" class="btn btn-danger">Cancelar</button>
        </div>    
        </form>
    </div>
  </div>
</template>
<script>

const _ = require('lodash');

export default {
  data() {
    return {
      editedUser: {...this.user},
      password: '',
      cambios: false,
      errorPassword: false ,
    };
  },
  mounted(){
    
  },
  components: {

  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
    user: Object,
  },
  methods: {
    cancel(){
      this.$emit('cancelar');
    },
    submit(){
      if (this.password) {
        this.editedUser.password = this.password;
      }
      if (!_.isEqual(this.user, this.editedUser)){
        this.$emit('enviar-usuario', this.editedUser);
        return;
      }
      this.cambios = true;
    }

    // async traerInfo() {
    //     await axios.delete(
    //         `${process.env.VUE_APP_RUTA_API}api/user/${this.id}`, 
    //         {headers: {'x-token': sessionStorage.getItem('x-token')}}
    //       )
    //       .then(response => {

    //         let indiceAEliminar = -1;
    //         for (var i = 0; i < this.users.length; i++) {
    //             if (this.users[i].id === this.id) {
    //                 indiceAEliminar = i;
    //                 break; // Detenemos el bucle una vez que encontramos el elemento
    //             }
    //         }

    //         if (indiceAEliminar !== -1) {
    //             // Si encontramos el elemento, lo eliminamos del array
    //             this.users.splice(indiceAEliminar, 1);
    //             console.log("Objeto eliminado");
    //         } else {
    //             console.log("Objeto no encontrado");
    //         }
    //         console.log('Usuario eliminado', response);
    //     })
    //     .catch(error => {
    //       console.error('Error al obtener la lista de usuarios:', error);
    //     });
        

    //     // Imprime el ID del usuario en la consola
    //     console.log(`ID del usuario: ${userId}`);
    // },
  }
};
</script>
