<template>
  <div>

    <div>
      <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
      >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
    ref="popoverDropdownRef"
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
    v-bind:class="{
      hidden: !dropdownPopoverShow,
      block: dropdownPopoverShow,
    }"
    >
    <button @click="editar">
      <a
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
      Editar
      </a>
    </button>
    <button @click="eliminarUsuario">
      <a
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
      Eliminar
    </a>
  </button>
  </div>
  </div>
</div>
</template>
<script>
import { createPopper } from "@popperjs/core";

export default {
  props: ['userId'],
  data() {
    return {
      dropdownPopoverShow: false,
    };
  },

  methods: {
    eliminarUsuario() {
      // Dispara un evento con el ID del usuario al hacer clic en "Ver"
      this.$emit('eliminar-usuario', this.userId);
    },
    editar() {
      this.$emit('editar-usuario', this.userId);
    },
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
