import { createApp } from "vue";
import App from "@/App.vue";
import router from "../src/router/routes";
import store from './store'


import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

const token = JSON.stringify(sessionStorage.getItem('x-token'));
const rol = JSON.stringify(sessionStorage.getItem('user-roles'));

if (token && rol) {
    store.dispatch('reloadedPage', {token, roles: rol})
}

createApp(App).use(store).use(router).mount("#app");
