<template>
  <div>
    <sidebar />
    <div class="relative md:ml-4 bg-blueGray-100 min-h-screen">
      <admin-navbar />
      <div class="relative tarjeta-nav md:pt-10 pb-30 pt-32 mb-10">
        <div class="px-4 md:px-10 mx-auto w-full mt-16">
        </div>
      </div>
      <!-- <header-stats /> -->
      <div class="px-4 md:px-12 mx-auto w-full -m-24 flex-grow">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
// import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    // HeaderStats,
    FooterAdmin,
  },
};
</script>
<style>
  .tarjeta-nav {
    background-color: #E32138;
  }
</style>
